import { defineStore } from 'pinia'

export const useStore = defineStore('main', {
    state: () => ({
        openai_endpoint: "https://api.openai.com",
        openai_key: ""
    }),
    getters: {

    },
    actions: {

    },
    persist: {
        enabled: true,
        strategies: [
            { key: 'main', storage: localStorage }
        ]
    }
})
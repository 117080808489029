<script setup lang="ts">
import { reactive, watch, ref, onMounted, computed, onBeforeMount } from "vue";

import { useMagicKeys } from "@vueuse/core";

import { marked } from "marked";
import hljs from "highlight.js";
import "highlight.js/styles/base16/solarized-light.css";

import { useStore } from "./store";

marked.setOptions({
  highlight: (code) => hljs.highlightAuto(code).value,
  // silent:true
  // async: true,
  langPrefix: "hljs language-",
});

const store = useStore();

const keys = useMagicKeys();
const slash = keys["Ctrl+/"];
const colon = keys["Ctrl+;"];

const mdContent = computed(() => marked.parse(data.outputText));

watch(slash, (v) => {
  if (v) data.inputDialogShow = !data.inputDialogShow;
});

watch(colon, (v) => {
  if (v) data.settingDialogShow = !data.settingDialogShow;
});

const inputBox = ref<HTMLElement>();
const outputBox = ref<HTMLElement>();
const scrollDiv = ref<HTMLElement>();

const data = reactive({
  inputText: "",
  outputText: "",
  inputDialogShow: true,
  settingDialogShow: false,
});

const getOpenSearchParam = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const params: any = {};
  for (let [key, value] of searchParams.entries()) {
    params[key] = value;
  }
  return params;
};

onBeforeMount(() => {
  const params = getOpenSearchParam();
  if (params.q) {
    data.inputDialogShow = false;
    data.inputText = params.q;
    onFindClick();
  } else {
    data.inputDialogShow = true;
  }
});

onMounted(() => {
  if (store.openai_key.trim().length == 0) {
    data.settingDialogShow = true;
  }
});

watch(data, ({ inputDialogShow }) => {
  if (inputDialogShow) {
    setTimeout(() => {
      inputBox.value?.focus();
    }, 200);
  }
  if (scrollDiv.value) {
    scrollDiv.value.scrollTop = scrollDiv.value.scrollHeight;
  }
});

const callapi = async (messages: string) => {
  const completion = await fetch(
    `${store.openai_endpoint}/v1/chat/completions`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${store.openai_key}`,
      },
      method: "POST",
      body: JSON.stringify({
        model: "gpt-3.5-turbo",
        messages: [{ role: "user", content: messages }],
        top_p: 0.1,
        stream: true,
      }),
    }
  );

  return completion.body;
};

const onFindClick = async () => {
  if (data.inputText.trim().length) {
    data.outputText += `<div><H4>${data.inputText}</H4></div>`;
    await callai();
    data.inputText = "";
    data.inputDialogShow = false;
  }
};

const onSettingApplyClick = async () => {
  data.settingDialogShow = false;
};

const onCleanClick = () => {
  data.outputText = "";
};

const callai = async () => {
  const decoder = new TextDecoder("utf-8");
  callapi(data.inputText).then((res) => {
    const reader = res?.getReader();
    let buffer = "";
    data.outputText += "<div>";
    const pipe = () => {
      reader?.read().then(({ value, done }) => {
        if (value) {
          let d = decoder.decode(value);
          buffer += d;
          let matchArr = [...buffer.matchAll(/({"content":".*?"})/g)];
          if (matchArr.length) {
            let n = matchArr[matchArr.length - 1].index || 0;
            let arr = matchArr.map((i) => i[1]);
            if (arr.length) {
              buffer = buffer.substring(n + arr[arr.length - 1].length);
            }

            arr.forEach((i) => {
              const jsobObj = JSON.parse(i);
              data.outputText += jsobObj.content;
            });
          }
        }
        if (!done) {
          pipe();
        } else {
          // console.log(buffer);
          data.outputText += "</div>";
        }
      });
    };
    pipe();
  });
};
</script>

<template>
  <div class="flex-column h-full">
    <div class="window h-full" style="width: 60rem; height: 40rem">
      <div class="title-bar">
        <button aria-label="Close" class="close" @click="onCleanClick"></button>
        <h1 class="title">Result</h1>
        <button aria-label="Resize" class="resize"></button>
      </div>
      <div class="separator"></div>
      <div class="window-pane p-1" ref="scrollDiv">
        <div
          ref="outputBox"
          class="w-full overflow-none mb-4 output font-16"
          v-html="mdContent"
        ></div>
      </div>
    </div>
  </div>

  <Transition
    name="s"
    enter-active-class="animate__animated animate__bounceIn"
    leave-active-class="animate__animated animate__bounceOut"
  >
    <div
      class="window scale-down dialog animate__animated animate__bounceIn"
      style="width: 30rem"
      v-show="data.inputDialogShow"
    >
      <div class="title-bar">
        <button
          aria-label="Close"
          class="close"
          @click="data.inputDialogShow = !data.inputDialogShow"
        ></button>
        <h1 class="title">Search Dialog</h1>
        <!-- <button aria-label="Resize" disabled class="hidden"></button> -->
      </div>
      <div class="separator"></div>

      <div class="modeless-dialog">
        <section class="field-row" style="justify-content: flex-start">
          <label for="text_find" class="modeless-text">Find:</label>
          <input
            id="text_find"
            ref="inputBox"
            type="text"
            style="width: 100%"
            placeholder=""
            autofocus
            v-model="data.inputText"
            @keyup.enter="onFindClick"
          />
        </section>
        <section class="field-row" style="justify-content: flex-end">
          <button class="btn" style="width: 95px" @click="onFindClick">
            Find
          </button>
        </section>
      </div>
    </div>
  </Transition>

  <Transition
    name="setting-dialog"
    enter-active-class="animate__animated animate__bounceIn"
    leave-active-class="animate__animated animate__bounceOut"
  >
    <div
      class="window scale-down dialog animate__animated animate__bounceIn"
      style="width: 30rem; height: 200px"
      v-show="data.settingDialogShow"
    >
      <div class="title-bar">
        <button
          aria-label="Close"
          class="close"
          @click="data.settingDialogShow = !data.settingDialogShow"
        ></button>
        <h1 class="title">Setting Dialog</h1>
        <!-- <button aria-label="Resize" disabled class="hidden"></button> -->
      </div>
      <div class="separator"></div>

      <div class="modeless-dialog">
        <section class="field-row" style="justify-content: flex-start">
          <label for="text_key" class="modeless-text">API KEY:</label>
          <input
            id="text_key"
            type="text"
            style="width: 100%"
            placeholder=""
            autofocus
            v-model="store.openai_key"
          />
        </section>
        <section class="field-row" style="justify-content: flex-start">
          <label for="text_endpoint" class="modeless-text">API URL:</label>
          <input
            id="text_endpoint"
            type="text"
            style="width: 100%"
            placeholder=""
            autofocus
            v-model="store.openai_endpoint"
          />
        </section>
        <section class="field-row" style="justify-content: flex-end">
          <button class="btn" style="width: 95px" @click="onSettingApplyClick">
            Apply
          </button>
        </section>
      </div>
    </div>
  </Transition>
</template>

<style>
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.resize-none {
  resize: none;
}

.h-200px {
  height: 200px;
}

.dialog {
  position: fixed;
  /* top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  z-index: 1000;
  width: 400px;
  height: 130px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  margin: auto !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* display: none; */
}

.p-1 {
  padding: 1rem;
}

.border-none {
  border: none;
}

textarea:focus {
  background: #fff;
  color: #000;
  outline: none;
}

.overflow-none {
  overflow: hidden;
}

.mb-4 {
  margin-bottom: 4rem;
}

.output {
  font-family: system-ui, sans-serif, Arial, Courier New;
}

.modeless-text {
  width: 5rem;
}

.font-16 {
  font-size: 16px;
}
</style>
